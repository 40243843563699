<template>
  <div class="page-pets animate__animated animate__fadeIn pt-4">
    <div class="text-center">
      <img
        :src="`${urlBucket}/form/assets/sloth-pets.png`"
        class="mb-4"
        @load="
          (e) => {
            e.target.style.display = 'unset';
            hideSpinner('animation');
          }
        "
        width="260px"
        style="display: none"
        alt="pet header"
      />
      <div id="animation" class="text-center my-5">
        <img src="/assets/img/spinner-loading.gif" width="100px" alt="" />
      </div>
    </div>

    <!--My pet - My next pet is a... -->
    <h5 class="fs-la">{{ twoPetsOrMore ? " My next pet" : "My pet" }} is a...?</h5>
    <p class="mb-3 fs-sm">You can complete this multiple time for more pets</p>
    <div class="row ps-2">
      <div class="col-3 mb-3 pe-3 ps-0" v-for="(pet, index) in petsTypes" :key="index">
        <div
          class="p-1 squares h-100 hvr"
          :class="{ 'hvr-radial-out': !isMobile(), selected: currentPet.type == pet['type'] }"
          @click="currentPet.type = pet['type']"
        >
          <img
            class="mt-1"
            :src="`${urlBucket}/form/assets/${pet.type}.png`"
            width="40"
            :alt="`${pet.type}`"
            style="display: none"
            @load="
              (e) => {
                e.target.style.display = 'unset';
                hideSpinner(`${pet['type']}`);
              }
            "
          />
          <div :id="`${pet['type']}`" class="text-center my-2">
            <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
          </div>
          <p class="fw-bold mb-0 fs-sm">{{ pet["type"] }}</p>
        </div>
      </div>
    </div>
    <p v-if="errors.petType" style="color: #c64a45">* Field required</p>

    <!-- Pet Name -->
    <h5 class="fs-la mt-4">Great, what's their name?</h5>
    <div class="d-flex">
      <input
        class="mb-1 p-3 ps-4 w-100 fs-md"
        type="text"
        placeholder="e.g. 'Toby'"
        v-model="currentPet.name"
      />
    </div>
    <p v-if="errors.petName" style="color: #c64a45">* Field required</p>
    <!-- What breed is  {{pteName}}? -->
    <div v-if="currentPet.type == 'Dog'">
      <h5 class="fs-la mt-4 mb-3">What breed is {{ currentPet.name }}?</h5>
      <input
        class="mb-1 p-3 ps-4 w-100 fs-md"
        type="text"
        placeholder="e.g. 'Springer Spaniel'"
        v-model="currentPet.breed"
      />
    </div>

    <!-- Select your current pet food subscription -->
    <div class="mt-4">
      <h5 class="fs-la">Select your current pet food subscription</h5>

      <Select
        class-names="w-100 fs-md"
        placeholder="e.g. 'Tails'"
        :options="petFoods"
        label="name"
        @selectChange="
          (e) => {
            currentPet.foodProvider = e;
          }
        "
        :has-image-options="true"
      />

      <div class="chip-list-wrapper mt-2 mb-2">
        <div
          v-if="currentPet.foodProvider"
          class="chip me-1 mb-2"
          @click="currentPet.foodProvider = null"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ currentPet.foodProvider.name }}</p>
        </div>
      </div>
    </div>

    <!-- Select your current Flea and Worming subscription(s) -->
    <div class="mt-4">
      <h5 class="fs-la">Select your current Flea and Worming subscription(s)</h5>

      <Select
        class-names="w-100 fs-md"
        placeholder="e.g. 'VetBox'"
        :options="fleaWormingSubscriptions"
        label="name"
        @selectChange="(e) => selectChange('fleaWorming', e)"
        :has-image-options="true"
      />

      <div class="chip-list-wrapper mt-2 mb-2">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in currentPet.fleaWorming"
          :key="index"
          @click="deleteItem('fleaWorming', index)"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item.name }}</p>
        </div>
      </div>
    </div>

    <!-- Select your current pet insurer(s) -->
    <div class="mt-4">
      <h5 class="fs-la">Select your current pet insurer(s)</h5>

      <Select
        class-names="w-100 fs-md"
        placeholder="e.g. 'RSPCA'"
        :options="pet"
        label="insurer"
        @selectChange="(e) => selectChange('currentInsurer', e)"
        :has-image-options="true"
      />

      <div class="chip-list-wrapper mt-2 mb-2">
        <div
          class="chip me-1 mb-2"
          v-for="(item, index) in currentPet.currentInsurer"
          :key="index"
          @click="deleteItem('currentInsurer', index)"
        >
          <i class="fa-sharp fa-solid fa-circle-check"></i>
          <p class="ms-1 mb-0">{{ item.insurer }}</p>
        </div>
      </div>
    </div>

    <h5 class="fs-la mt-4 mb-3">Would you like to update the microchiping database?</h5>

    <div class="d-flex">
      <div
        :class="`box-answers fs-md ${currentPet.microchipping === true ? 'checked' : ''}`"
        @click="updateValue('microchipping', true)"
      >
        Yes
      </div>
      <div
        :class="`box-answers fs-md ${currentPet.microchipping === false ? 'checked' : ''} ms-3`"
        @click="updateValue('microchipping', false)"
      >
        No
      </div>
    </div>

    <h5 class="fs-la mt-4 mb-3">Would you like to add another pet?</h5>

    <div class="d-flex mb-4">
      <div
        :class="`box-answers fs-md ${addPet === true ? 'checked' : ''}`"
        @click="
          () => {
            addPet = true;
            addOtherPet();
          }
        "
      >
        Yes
      </div>
      <div
        :class="`box-answers fs-md ${addPet === false ? 'checked' : ''} ms-3`"
        @click="
          () => {
            addPet = false;
          }
        "
      >
        No
      </div>
    </div>

    <!-- Your pets -->
    <h5 class="fs-la mt-1 mb-3" v-if="pets.length > 0">Your pets</h5>

    <div class="row mb-3">
      <div class="col-4 text-center" v-for="(pet, index) in pets" :key="index">
        <div v-if="pet.name.length > 0 && pet.type.length > 0">
          <img
            :src="`${urlBucket}/form/assets/${pet.type}.png`"
            width="55"
            alt=""
            class="mt-1 mb-2"
          />
          <div class="d-flex justify-content-center">
            <div class="chip me-1 mb-1">
              <i class="fa-sharp fa-solid fa-circle-check"></i>
              <p class="ms-2 mb-0">{{ pet["name"] }}</p>
            </div>
          </div>
          <p class="mb-0 fs-xs text-underline" @click="deletePet(index)">Remove</p>
        </div>
      </div>
    </div>

    <!-- Label Pages Bottom -->
    <div class="mb-2">
      <div class="label-pages d-flex p-3 align-items-center">
        <img :src="require('@/assets/check-y.png')" width="30px" alt="" class="ms-2 me-2" />
        <p class="ms-1 fs-sm mb-0">Excellent, we'll update those details for your pet(s)</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import { navigateTo } from "../router/index";
import { isMobile } from "../utils/isMobile";
import Select from "../components/Select.vue";
import { scrollToTop } from "../utils/scrollToTop.js";
import { pet } from "../data/lists.js";
import { setRoute } from "../router/setRoute.js";
// import { vehicle, life, pet, travel } from "../data/lists.js";
// @ is an alias to /src

export default {
  name: "PagePets",
  components: { vSelect, Select },
  data() {
    return {
      pet, //Pet Insurances
      isMobile,
      currentPet: {
        type: "",
        name: "",
        foodProvider: null,
        fleaWorming: [],
        currentInsurer: [],
        microchipping: false,
      },
      petsTypes: [
        { type: "Dog" },
        { type: "Cat" },
        { type: "Rabbit" },
        { type: "Rodent" },
        { type: "Horse" },
        { type: "Reptile" },
        { type: "Bird" },
      ],
      petFoods: [
        { name: "Pets at Home", icon: "icons/Pets at Home.png" },
        { name: "Bella & Duke", icon: "icons/Bella & Duke.png" },
        { name: "Lily's Kitchen", icon: "icons/Lily's Kitchen.png" },
        { name: "Tails", icon: "icons/Tails.png" },
        { name: "Butternut Box", icon: "icons/Butternut Box.png" },
        { name: "Harrington's Pet Food", icon: "icons/Harrington's Pet Food.png" },
        { name: "Pure Pet Food", icon: "icons/Pure Pet Food.png" },
        { name: "Different Dog", icon: "icons/Different Dog.png" },
        { name: "Poppy's Picnic", icon: "icons/Poppy's Picnic.png" },
        { name: "Blink! Cats", icon: "icons/Blink! Cats.png" },
        { name: "Naturaw", icon: "icons/Naturaw.png" },
        { name: "Skinner's", icon: "icons/Skinner's.png" },
        { name: "Kasper & Kitty", icon: "icons/Kasper & Kitty.png" },
      ],
      fleaWormingSubscriptions: [
        { name: "Pets at Home", icon: "icons/Pets at Home.png" },
        { name: "VetBox", icon: "icons/VetBox.png" },
        { name: "ItchPet", icon: "icons/ItchPet.png" },
        { name: "Protect My Pet", icon: "icons/Protect My Pet.png" },
      ],
      addPet: false,
      twoPetsOrMore: false,
      errors: {
        petType: false,
        petName: false,
      },
    };
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    validateInputs() {
      if (this.pets.length == 0) {
        scrollToTop();
        if (!this.currentPet.type) {
          // scrollToTop()
          this.errors.petType = true;
        } else {
          this.errors.petType = false;
        }

        if (!this.currentPet.name) {
          // scrollToTop()
          this.errors.petName = true;
        } else {
          this.errors.petName = false;
        }
      }
    },
    updateValue(itemName, value) {
      this.currentPet[itemName] = value;
    },
    selectChange(itemName, value) {
      // console.log("itemName", value);
      let array = this.currentPet[itemName];

      if (array.includes(value)) {
        let index = array.indexOf(value);
        array.splice(index, 1);
      } else {
        array.push(value);
      }

      this.currentPet[itemName] = array;
    },
    editingCurrentPet() {
      if (this.currentPet.name.length > 0 && this.currentPet.type.length > 0) {
        let array = this.pets;

        let obj = { ...this.currentPet };

        if (array.length == 0) {
          array.push(obj);
        }

        array[array.length - 1] = obj;

        this.$store.commit("formStore/setField", ["pets", array]);
      }
    },
    deleteItem(itemName, index) {
      let array = this.currentPet[itemName];
      array.splice(index, 1);
      this.currentPet[itemName] = array;
    },
    deletePet(index) {
      let array = this.pets;
      array.splice(index, 1);
      this.$store.commit("formStore/setField", [`pets`, array]);
    },
    addOtherPet() {
      scrollToTop();

      if (this.currentPet.name == "" || this.currentPet.type == "") {
        this.validateInputs();
        return;
      }

      this.clearCurrentPet();

      if (this.pets.length > 0) {
        this.twoPetsOrMore = true;
      }

      setTimeout(() => {
        this.addPet = false;
      }, 600);

      this.$store.commit("formStore/setField", [`pets`, [...this.pets, this.currentPet]]);
    },
    clearCurrentPet() {
      this.currentPet.type = "";
      this.currentPet.name = "";
      this.currentPet.foodProvider = null;
      this.currentPet.fleaWorming = [];
      this.currentPet.currentInsurer = [];
      this.currentPet.microchipping = false;

      this.errors.petName = false;
      this.errors.petType = false;
    },
  },
  mounted() {
    // console.log('mounted')
    this.$store.commit("setField", ["titleNavbar", "Your Pets"]);

    const validatePage = () => {
      // let route = "/about-you";
      // const setRoute = () => {
      //   if (
      //     this.informPension ||
      //     this.informCharities ||
      //     this.informGyms ||
      //     this.informHomecare ||
      //     this.informBreakdown
      //   ) {
      //     route = "/general-info-3";
      //     return;
      //   }

      //   if (this.informBanks) {
      //     route = "/banks";
      //     return;
      //   }

      //   if (this.informCouncilTax || this.informWaterSewerage || this.informEnergy) {
      //     route = "/councils";
      //     return;
      //   }

      //   if (this.informMobile) {
      //     route = "/mobile-providers";
      //     return;
      //   }

      //   if (this.informLoyalty) {
      //     route = "/loyalties";
      //     return;
      //   }
      // };

      // setRoute();

      this.validateInputs();

      this.$store.commit("formStore/setField", [
        `pets`,
        this.pets.filter((pet) => pet.name !== "" || pet.type !== ""),
      ]);

      if (!Object.values(this.errors).includes(true)) {
        navigateTo(setRoute(this.state));
      }
    };

    document.getElementById("mainButton").onclick = (ev) => {
      validatePage();
    };

    document.onkeydown = (e) => {
      if (e.key == "ArrowLeft" && document.activeElement.tagName !== "INPUT") {
        this.$router.back();
      }

      if (e.key == "ArrowRight" && document.activeElement.tagName !== "INPUT") {
        validatePage();
      }
    };
  },
  created() {
    // console.log('created')

    if (this.pets.length > 1) {
      this.twoPetsOrMore = true;
    }
  },
  computed: {
    ...mapState([""]),
    ...mapState("formStore", [
      "referrer",
      "baseURL",
      "urlBucket",
      "informPension",
      "informCouncilTax",
      "informWaterSewerage",
      "informCharities",
      "informGyms",
      "informBanks",
      "informLoyalty",
      "informMobile",
      "informNHS",
      "informDentist",
      "informEnergy",
      "informInsurance",
      "informBreakdown",
      "informHomecare",
      "informPets",
      "informOpticians",
      "pets",
    ]),
    ...mapGetters("formStore", ["state"]),
  },
  watch: {
    currentPet: {
      handler: function (newVal, oldVal) {
        if (newVal.type != "Dog") {
          delete this.currentPet["breed"];
        }

        this.editingCurrentPet();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.page-pets {
  .squares {
    text-align: center;
    background: #fff;
    border-radius: 5px;
    cursor: pointer;
    color: black;
    box-shadow: 0 1px 7px #0b2d9633;
    transition: all 0.1s ease-in-out;

    img {
      width: 60%;
    }
  }

  .selected {
    background-color: #1ea6d4;
    color: white !important;
  }

  .box-answers {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    padding: 14px 34px;
    text-align: center;
    cursor: pointer;
  }

  .box-answers-services {
    border: 1px solid transparent;
    background-color: white;
    color: black;
    border-radius: 6px;
    width: 110px;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
  }

  .checked {
    border: 1px solid #2ec989;
    background-color: #1e9d68;
    color: white;
  }

  .chip-list-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }

  .chip {
    display: flex;
    align-items: center;
    padding: 7px;
    height: 30px;
    border-radius: 25px;
    background-color: #f1f1f1;
    cursor: pointer;

    p {
      color: black;
      font-size: 14px;
    }

    .fa-circle-check {
      color: #1e9d68;
      font-size: 16px;
    }
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 65px;
    height: 30px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
    }
  }

  input:checked + .slider {
    background-color: #2196f3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(35px);
    -ms-transform: translateX(35px);
    transform: translateX(35px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;

    &:before {
      border-radius: 50%;
    }
  }
}
</style>
