<template>
  <div class="input-search-container mb-2">
    <div class="container-search-icon">
      <i class="fa-solid fa-magnifying-glass"></i>
    </div>
    <vSelect
      :class="classNames"
      :label="label"
      :clearable="false"
      :options="options"
      :placeholder="placeholder"
      :multiple="false"
      @option:selected="(ev) =>{ $emit('selectChange', ev), modelValueInput = ''}"
      @search="onSearch"
      v-model="modelValueInput"
    >
      <template slot="no-options">
        <div class="text-center">
          <img :src="require('@/assets/binoculars.png')" class="mb-3" alt="" />

          <p class="mb-1">We didn't find anything for '{{ search }}'</p>
          <!-- <p
            class="enter-manually"
            @click="
              () => {
                updateValue('gpsManual', true);
                deleteGPS(0);
              }
            "
          >
            Enter manually
          </p> -->
        </div>
      </template>
      <template slot="option" slot-scope="option">
        <div class="d-flex align-items-center py-1">
          <img
            v-if="hasImageOptions"
            :src="`${urlBucket}/${option.icon}`"
            width="30px"
            class="me-3"
            style="display: none"
            @load="
              (e) => {
                e.target.style.display = 'unset';
                hideSpinner(option.name ?? option.insurer);
              }
            "
          />
          <div v-if="hasImageOptions" :id="option.name ?? option.insurer" class="text-center me-3">
            <img src="/assets/img/spinner-loading.gif" width="30px" alt="" />
          </div>
          <p class="mb-0 fs-md">{{ option[label] }}</p>
        </div>
      </template>
    </vSelect>
  </div>
</template>

<script>
import { mapState } from "vuex";
import vSelect from "vue-select";

export default {
  name: "Select",
  components: {
    vSelect,
  },
  props: {
    classNames: {
      type: String,
    },
    label: {
      type: String,
    },
    // changeValueInput:{
    //   type: String,
    // },
    options: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
    hasImageOptions: {
      type: Boolean,
      default: false,
    },
    hasManually: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modelValueInput:'',
      search: "",
    };
  },
  mounted() {
    // console.log(this.options);
  },
  methods: {
    hideSpinner(id) {
      document.getElementById(id).style.display = "none";
    },
    onSearch(search, loading) {
      // loading(true)
      this.search = search;
    },
    generateUniqueId() {
      return Math.random().toString(30).substring(2);
    },
  },
  computed: {
    ...mapState("formStore", ["referrer","urlBucket"]),
  },
};
</script>

<style lang="scss">
.input-search-container {
  .icon-search {
    color: #8a8a8a;
    float: left;
    margin-left: 15px;
    margin-top: -65px;
    position: relative;
    z-index: 2;
    font-size: 23px;
  }

  .v-select {
    color: black;
    font-weight: 600;

    .vs__actions {
      margin-right: 5px;
    }

    .vs__dropdown-toggle {
      height: 55px;
      border: none;
    }

    .vs__search::placeholder {
      color: #8a8a8a;
    }
    .vs__dropdown-menu {
      p {
        font-weight: 200;
      }
    }

    .enter-manually {
      cursor: pointer;
      font-size: 12px;
      color: #8a8a8a;
      text-decoration: underline;
    }
  }
}
</style>
